// Libraries
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Header from "../../components/Header";
import Button from "../../components/Button";

// Redux
import { importBackDocImage } from "../../redux/globalData/actions";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

// Mitek service
import { takePhotoMitek } from "../../services/mitek/mitekServiceHandler";

// Utils
import rotate from "../../utils/rotateImage";

// Constants
import { DOCUMENT_TYPE } from "../../constants/mitekConstants";

function BackAdvice() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const environmentData = useSelector((state) => state.environmentData);
  const [loading, setLoading] = useState(false);

  function nextPage() {
    setLoading(true);
    takePhotoMitek(
      DOCUMENT_TYPE,
      (result) => {
        setLoading(false);
        const fullWidth = window.innerWidth;
        const fullHeight = window.innerHeight;
        if (fullWidth < fullHeight) {
          rotate(result.response.imageData, -90, (imageRotated) => {
            dispatch(importBackDocImage(imageRotated));
          });
        } else {
          dispatch(importBackDocImage(result.response.imageData));
        }

        navigation("/back-result");
      },
      () => {
        setLoading(false);
      },
      (error) => {
        alert("Error al tomar la imagen. Más detalle en la consola.");
        console.log(error);
      },
      "back"
    );
  }

  return (
    <div className="flex flex-col justify-between items-center w-screen h-full">
      <Header index="2 / 3" backURL="/front-result" />
      <div className="flex flex-col grow items-center w-screen">
        <div className="w-10/12 font-400-20 primary-color mt-8">
          Alinea y captura la{" "}
          <span className="font-700-22 underline">cara trasera</span> de tu
          documento de identidad.
        </div>
        <img
          className="w-6/12 mt-3"
          alt="back-gif"
          src="/assets/animations/back-dni.gif"
        ></img>
        <div className="w-10/12 mt-5 mb-8">
          <div className="font-700-16 primary-color">Sigue estos consejos</div>
          <div className="mt-2">
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-1"
                src={`/assets/icons/${environmentData.business}/advice-icon-1.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                Usa tu documento original en vigor.
              </div>
            </div>
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-2"
                src={`/assets/icons/${environmentData.business}/advice-icon-2.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                Utiliza un fondo oscuro y uniforme.
              </div>
            </div>
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-3"
                src={`/assets/icons/${environmentData.business}/advice-icon-3.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                Sitúate en una zona iluminada.
              </div>
            </div>
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-4"
                src={`/assets/icons/${environmentData.business}/advice-icon-4.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                Evita destellos sobre el documento.
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Button className="mb-6" text="Siguiente" />
      ) : (
        <Button className="mb-6" text="Siguiente" onClick={nextPage} />
      )}
    </div>
  );
}

export default BackAdvice;
