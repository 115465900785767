// Dependencies
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Api service
import { getLogsPagination } from "../../services/api/apiHandler";

function MitekTable({ page = 1, business }) {
  const [logs, setLogs] = useState({ data: null, loaded: false });
  const navigate = useNavigate();

  function toDetailView(logId) {
    navigate(`/admin/${logId}`, { state: { pageToBack: logs.data.page } });
  }

  function nextListPage() {
    setLogs({
      loaded: false,
      data: [],
    });
    navigate(`/admin?dashboard_page=${logs.data.page + 1}&service=mitek`);
  }

  function previousListPage() {
    setLogs({
      loaded: false,
      data: [],
    });
    navigate(`/admin?dashboard_page=${logs.data.page - 1}&service=mitek`);
  }

  useEffect(() => {
    let isMounted = true;

    getLogsPagination(page, business)
      .then((resp) => {
        if (isMounted) {
          setLogs({ data: resp.data, loaded: true });
        }
      })
      .catch((error) => {
        if (isMounted) {
          alert("Error al recoger los datos");
          console.log(error);
        }
      });

    return () => (isMounted = false);
  }, [page, business]);

  return (
    <div className="flex flex-row flex-wrap rounded-md dashboard bg-white border border-slate-400">
      <div className="flex flex-row w-full py-1 primary-color font-bold border-b border-slate-300">
        <div className="table-item-header w-1/5">Email</div>
        <div className="table-item-header w-1/5">Hubspot ID</div>
        <div className="table-item-header w-1/5">Resultado Comp. Facial</div>
        <div className="table-item-header w-1/5">Resultado Documento</div>
        <div className="table-item-header w-1/5">Fecha</div>
        <div className="w-10 mr-10"></div>
      </div>
      {logs.loaded ? (
        logs.data.totalResults !== 0 ? (
          logs.data.data.map((log, index) => {
            return (
              <div
                key={index}
                className="flex items-center hover:bg-slate-100 ease-in duration-150 items-line py-1"
                onClick={() => toDetailView(log._id)}
              >
                <div className="table-item w-1/5 ellipsis">
                  {log.request.email}
                </div>
                <div className="table-item w-1/5 ellipsis">
                  {log.request.hubspotId}
                </div>
                <div className="table-item w-1/5 ellipsis">
                  {log.retrievalFaceComparison && log.retrievalFaceComparison.approval === "APPROVED" ? (
                    <span className="bg-green-200 rounded-2xl px-3 primary-color font-bold">
                      Aprobado
                    </span>
                  ) : (
                    <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold">
                      No aprobado
                    </span>
                  )}
                </div>
                <div className="table-item w-1/5 ellipsis">
                  {log.error ? (
                    <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold">
                      Error
                    </span>
                  ) : log.retrievalVerify ? (
                    log.retrievalVerify.acceptance.result === "ACCEPTED" ? (
                      log.retrievalVerify.findings.documentApproval ===
                      "APPROVED" ? (
                        <span className="bg-green-200 rounded-2xl px-3 primary-color font-bold">
                          Aprobado
                        </span>
                      ) : (
                        <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold">
                          No aprobado
                        </span>
                      )
                    ) : (
                      <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold">
                        Rechazado
                      </span>
                    )
                  ) : (
                    <span className="bg-slate-200 rounded-2xl px-3 text-black font-bold">
                      Pendiente
                    </span>
                  )}
                </div>
                <div className="table-item w-1/5 ellipsis">
                  {new Date(log.created_at).toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </div>
                <div className="w-10 px-2 mr-10">
                  <img alt="view" src="/assets/icons/eye-icon.svg" />
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex flex-col justify-center items-center primary-color font-bold w-full h-96">
            <div className="mb-7">¡No hay datos para mostrar!</div>
            <div>
              <img
                className="w-16"
                alt="no-data"
                src={`/assets/icons/${business}/no-data.svg`}
              />
            </div>
          </div>
        )
      ) : (
        <div className="flex justify-center items-center primary-color font-bold w-full h-96">
          Cargando datos...
        </div>
      )}
      {logs.loaded && logs.data.totalResults !== 0 && (
        <div className="flex flex-row justify-between items-center w-full px-7 py-3 primary-color border-t border-slate-300">
          {logs.data.page === logs.data.totalPages ? (
            <div>
              Mostrando{" "}
              <span className="font-bold">
                {logs.data.resultsPerPage * (logs.data.page - 1) + 1}
              </span>{" "}
              a <span className="font-bold">{logs.data.totalResults}</span> de{" "}
              <span className="font-bold">{logs.data.totalResults}</span>{" "}
              resultados
            </div>
          ) : (
            <div>
              Mostrando{" "}
              <span className="font-bold">
                {logs.data.resultsPerPage * (logs.data.page - 1) + 1}
              </span>{" "}
              a{" "}
              <span className="font-bold">
                {logs.data.resultsPerPage * logs.data.page}
              </span>{" "}
              de <span className="font-bold">{logs.data.totalResults}</span>{" "}
              resultados
            </div>
          )}
          <div className="flex flex-row">
            {logs.data.page !== 1 && (
              <div
                className="mr-2 cursor-pointer border-primary font-bold rounded-md px-3 py-1 ease-in duration-200 button-cta-admin"
                onClick={previousListPage}
              >
                Anterior
              </div>
            )}
            {logs.data.page < logs.data.totalPages && (
              <div
                className="ml-2 cursor-pointer border-primary font-bold rounded-md px-3 py-1 ease-in duration-200 button-cta-admin"
                onClick={nextListPage}
              >
                Siguiente
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MitekTable;
